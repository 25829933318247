<template>
  <div :key="mypageKey">
    <div class="div_title">
      <context :context=cate />
      <policies/>
    </div>
    <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true" > 
      <Tabs :value=tIdx>
        <TabList id="mypageMenu">
          <Tab value=0 @click="click({type:'menu', tIdx:'0'})">관심</Tab>
          <Tab value=1 @click="click({type:'menu', tIdx:'1'})">카트</Tab>
        </TabList>
        <TabPanels>
          <TabPanel value=0>
            <div v-if="likeList === undefined || likeList.length === 0" class="no-result">
              <div class="div_title">
                <Button label="전체선택" raised size="small" disabled /> 
                <Button @click="search({type:'SELECT_ORDER_LIKE', page:1})" label="조회" raised size="small" />
              </div>

              <span>관심상품이 없습니다</span>
            </div>
            <div v-else>
              <div class="div_title">
                <span>
                  <Button @click="click({type:'selectAll'})" label="전체선택" raised size="small" /> 
                </span>
                <span class="div_title2">
                  <Button @click="click({type:'INSERT_ORDER_CART'})" label="카트담기" raised size="small" />&nbsp;
                  <Button @click="click({type:'DELETE_ORDER_LIKE', idxList:undefined})" label="삭제" raised size="small" />&nbsp;
                  <Button @click="search({type:'SELECT_ORDER_LIKE', page:1})" label="조회" raised size="small" />
                </span>
              </div>
              <DataTable stripedRows v-model:selection="likeSelectedRows" v-model:expandedRows="likeExpandedRows" @rowExpand="onRowExpand" :value="likeList" dataKey="IDX" >
                <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                <Column expander style="width: 5rem" />
                <Column field="" header="날짜">
                  <template #body="slotProps">
                    {{ this.api.getDateByFormat(slotProps.data.REG_DATE, 'YYYY.MM.DD') }}
                  </template>
                </Column>
                <Column field="" header="상품명">
                  <template #body="slotProps">
                    {{ this.api.textCut(slotProps.data.NAME, 30) }}
                  </template>
                </Column>
                <Column field="COUNTRY" header="나라"></Column>
                <template #expansion="slotProps">
                  <div class="div_center">
                    <Carousel :value="slotProps.data.IMG_LIST.split(',')" :responsiveOptions="responsiveOptions" :numVisible="Math.min(slotProps.data.IMG_LIST.split(',').length, 4)" :numScroll="1" circular:autoplayInterval="3000" style="width:100%">
                      <template #item="slotProps">
                        <img :src="slotProps.data" alt="GEEK9" style="max-width:100px;" />
                      </template>
                    </Carousel>
                  </div>
                  <Divider/>
                  <table class="dynamic-table">
                    <tr style="height:100px;">
                      <td class="mypage-value">
                        <table class="order_sub_table" style="table-layout:fixed;">
                          <tbody >
                            <tr style="height:30px;">
                              <td class="container_sub_style">
                                <div class="div_center">
                                  <div class="mypage-menu">IDX</div>
                                  <div class="mypage-value">{{slotProps.data.IDX}}</div>
                                </div>
                              </td>
                              <td class="container_sub_style">
                                <div class="div_center">
                                  <div class="mypage-menu">상품명</div>
                                  <div class="mypage-value">{{ slotProps.data.NAME }}</div>
                                </div>
                              </td>
                            </tr>
                            <tr style="height:30px;">
                              <td class="container_sub_style">
                                <div class="div_center">
                                  <div class="mypage-menu" >브랜드</div>
                                  <div class="mypage-value">{{ slotProps.data.BRAND }}</div>
                                </div>
                              </td>
                              <td class="container_sub_style">
                                <div class="div_center">
                                  <div class="mypage-menu">사이즈</div>
                                  <div class="mypage-value">{{ slotProps.data.SIZE }}</div>
                                </div>
                              </td>
                            </tr>
                            <tr style="height:30px;">
                              <td class="container_sub_style">
                                <div class="div_center">
                                  <div class="mypage-menu" >현지판매가</div>
                                  <div class="mypage-value" v-if="slotProps.data.COUNTRY === '홍콩'">{{ (Number(slotProps.data.ORIGIN_PRICE) - Number(slotProps.data.FOREIGN_DELIVERY_PRICE)).toFixed(2)  }} HKD </div>
                                  <div class="mypage-value" v-else-if="미국">{{ (Number(slotProps.data.ORIGIN_PRICE) - Number(slotProps.data.FOREIGN_DELIVERY_PRICE)).toFixed(2)  }} 달러</div>
                                  <div class="mypage-value" v-else>{{ (Number(slotProps.data.ORIGIN_PRICE) - Number(slotProps.data.FOREIGN_DELIVERY_PRICE)).toFixed(2)  }} 유로</div>
                                </div>
                              </td>
                              <td class="container_sub_style">
                                <div class="div_center">
                                  <div class="mypage-menu">현지배송비 <label style="color:blue;font-weight: normal;">(2)</label></div>
                                  <div class="mypage-value"> 
                                    {{ Number(slotProps.data.FOREIGN_DELIVERY_PRICE).toFixed(2) }}
                                    <label>{{ slotProps.data.COUNTRY === '홍콩' ? 'HKD' : slotProps.data.COUNTRY === '미국' ? '달러' : '유로' }}</label>
                                    <label style="font-weight: normal;font-style: italic;">(판매자➜GEEK9)</label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr style="height:30px;">
                              <td class="container_sub_style">
                                <div class="div_center">
                                  <div class="mypage-menu" >해외배송비 <label style="color:blueviolet;font-weight: normal;">(3)<br></label>
                                    <label style="font-weight: normal;font-style: italic;">(GEEK9➜한국)</label></div>
                                  <div class="mypage-value"><p>GEEK9창고 도착 후 확정</p></div>
                                </div>
                              </td>
                              <td class="container_sub_style">
                                <div class="div_center">
                                  <div class="mypage-menu">소비자결제<br><label style="color:brown;font-weight: normal;">(1)</label><label style="font-weight: normal;"> + </label><label style="color:blue;font-weight: normal;">(2)</label><label style="font-weight: normal;"> + </label><label style="color:blueviolet;font-weight: normal;">(3)</label><label style="font-weight: normal;"> + </label><label style="color:red;font-weight: normal;">수수료</label></div>
                                  <div class="mypage-value">
                                    <label style="font-weight: bold;">{{ Number(slotProps.data.SELL_PRICE).toLocaleString() }}원</label> + <label style="color:blueviolet;">(3)</label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr style="height:30px;">
                              <td class="container_sub_style">
                                <div class="div_center">
                                  <div class="mypage-menu" >해외판매자설명<br>
                                    <label style="font-weight: normal;font-style:italic;font-size:10px;color:blue;" class="link"><a target="_blank" :href="this.api.getGoogleTranslateUrl({country:slotProps.data.COUNTRY, desc:this.api.emojiRemover(slotProps.data.DESC)})">{{slotProps.data.COUNTRY}}어 번역하기</a></label></div>
                                  <div class="mypage-value"><p>{{ slotProps.data.DESC }}</p></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <Divider />
                  <div class="div_center">
                    <Button label="카트" raised size="small" @click="click({type:'INSERT_ORDER_CART', item:slotProps.data})" />
                    <Button label="주문" raised size="small" @click="click({type:'regOne', item:slotProps.data})" />
                    <Button label="삭제" raised size="small" @click="click({type:'DELETE_ORDER_LIKE', idxList:[slotProps.data.IDX]})" />
                  </div>                      
                  <br>
                </template>
              </DataTable>
            </div>
            <div class="div_center" v-if="likeList.length !== 0">
              <label @click="likePaging('prev')" class="link" >
                이전
              </label>
              <span style="margin:20px">{{ likePage }}</span>
              <label @click="likePaging('next')" class="link">
                다음
              </label>
            </div>
          </TabPanel>
          <TabPanel value=1>
            <div class="div_title">
              <span>
                <Button @click="click({type:'selectAll'})" label="전체선택" raised size="small" /> 
              </span>
              <span class="div_title2">
                <Button @click="click({type:'DELETE_ORDER_CART', idxList:undefined})" label="삭제" raised size="small" /> &nbsp;
                <Button @click="click({type:'SELECT_ORDER_CART', page:-1})" label="조회" raised size="small" /> 
              </span>
            </div>
            <div v-if="cartList === undefined || cartList.length === 0" class="no-result">
              <span>카트가 비었습니다</span>
            </div>
            <div v-else class="divider" style="background-color: black; height:2px;"/>
              <div v-for="(selectedItem, index) in cartList" :key="index">
                <div id="cart-wrapper" class="mypage-wrapper">
                  <div style="width: fit-content;">
                    <input class="normal-checkbox" type="checkbox" :value="selectedItem.IDX" :data-checkbox="index" @click="checkbox()" />
                  </div>
                  <div style="width: 150px;">
                    <img id='cart-img' @click="click({type:'imgList', imgList:selectedItem.IMG_LIST})" class="margin10 link" :src="selectedItem.IMG_URL"/>
                  </div>
                  <div id="cart-container" >
                    <div class="mypage-cart-wrapper" > 
                      <p>
                        <img class="country-flag" :src="this.api.getCountryFlag(selectedItem.COUNTRY)" style="float:left; margin-right:10px;">
                        {{ selectedItem.COUNTRY }}
                      </p>
                      <p v-if="!(selectedItem.BRAND === undefined || selectedItem.BRAND === '')"><label style="color:gray">[브랜드]</label> {{ selectedItem.BRAND }}</p>
                      <p><label style="color:gray">[상품명]</label> {{ selectedItem.NAME }}</p>
                      <p v-if="!(selectedItem.SIZE === undefined || selectedItem.SIZE === '')"><label style="color:gray">[사이즈]</label> {{ selectedItem.SIZE }}</p>
                      <p id="desc-window" v-if="!(selectedItem.DESC === undefined || selectedItem.DESC === null)" class="link" @click="click({type:'desc', desc:this.api.emojiRemover(selectedItem.DESC), country:selectedItem.COUNTRY})" style="font-style: italic;">해외판매자 상품설명</p>
                    </div>
                    <div class="mypage-cart-wrapper" >
                      <p>
                        <label style="float:right;" v-if="selectedItem.COUNTRY === '홍콩'">{{ (Number(selectedItem.ORIGIN_PRICE) - Number(selectedItem.FOREIGN_DELIVERY_PRICE)).toFixed(2)  }} HKD</label>
                        <label style="float:right;" v-else>{{ (Number(selectedItem.ORIGIN_PRICE) - Number(selectedItem.FOREIGN_DELIVERY_PRICE)).toFixed(2)  }} 유로</label>
                        <label style="color:gray">[현지판매가]</label> 
                        <label style="color:brown;font-weight: normal;">&nbsp; (1)</label>
                      </p>
                      <p>
                        <label style="float:right;" v-if="selectedItem.COUNTRY === '홍콩'">{{ selectedItem.FOREIGN_DELIVERY_PRICE }} HKD</label>
                        <label style="float:right;" v-else>{{ selectedItem.FOREIGN_DELIVERY_PRICE }} 유로</label>
                        <label style="color:gray">[현지배송비]</label><label style="color:blue;font-weight: normal;">&nbsp; (2)</label>
                      </p>
                      <p>
                        <label style="float:right;" >GEEK9창고 도착 후 확정</label>
                        <label style="color:gray">[해외배송비]</label><label style="color:blueviolet;font-weight: normal;">&nbsp; (3)</label>
                      </p>
                      <p>
                        <label style="color:gray">[소비자결제]</label> <label style="font-weight:bold; float:right;">{{ Number(selectedItem.SELL_PRICE).toLocaleString() }}원</label><br>
                        <label style="color:brown;font-weight: normal;">(1)</label><label style="font-weight: normal;"> + </label><label style="color:blue;font-weight: normal;">(2)</label><label style="font-weight: normal;"> + </label><label style="color:blueviolet;font-weight: normal;">(3)</label><label style="font-weight: normal;"> + </label><label style="color:red;font-weight: normal;">수수료</label>
                      </p>
                      <p id="desc-mobile" v-if="!(selectedItem.DESC === undefined || selectedItem.DESC === null)" class="link" @click="click({type:'desc', desc:selectedItem.DESC, country:selectedItem.COUNTRY})" style="font-style: italic;">해외판매자 상품설명</p>
                    </div>
                  </div>
                </div>
                <div v-if="cartList.length-1 === index" class="divider" style="background-color: black; height:2px;"/>
                <div v-else class="divider" style="background-color: gray; height:1px;"/>
              </div>
              <div v-if="!(cartList === undefined || cartList.length === 0)" class="trade_table">
                <p>
                  <label style="color:gray">[현지국가명]</label>
                  <span style="float:right;" >{{ this.selectedCountry }}</span>
                </p>
                <p>
                  <label style="color:gray">[현지판매가]</label>
                  <span style="float:right;">{{ totalForeignPrice.toLocaleString() }}{{ this.selectedCountry === '홍콩' ? 'hkd' : '유로'  }}</span>
                </p>
                <p>
                  <label style="color:gray">[현지배송비]</label>
                  <span style="float:right;">{{ totalForeignDeliveryPrice.toFixed(2) }}{{ this.selectedCountry === '홍콩' ? 'hkd' : '유로'  }}</span>
                </p>
                <p>
                  <label style="color:gray">[해외배송비]</label>
                  <span style="float:right;">추후결제요청</span>
                </p>
                <p>
                  <label style="color:gray">[총주문금액]</label>
                  <span style="float:right; color:red; font-weight:bold;">{{ totalWonPrice.toLocaleString() }}원 + 해외배송비</span>
                </p>
                <br>
                <div id="mypage_agree_section">
                  <div class="card">
                    <p class="agree">1. GEEK9는 통신판매중개자이며, 통신판매의 당사자가 아닙니다.</p>
                    <p class="agree">2. 상품, 상품정보, 거래에 관한 의무와 책임은 해외 판매자에게 있습니다.</p>
                    <p class="agree">3. 해당 상품은 해외에서 판매되고 있는 중고 상품으로 GEEK9에서는 상품과 해외 판매자에 대해 어떠한 보장/보증을 하지 않습니다.</p>
                    <p class="agree">4. GEEK9에서는 해외 판매자의 상품을 구매/배송대행만 할 뿐, 상품과 판매자에 대한 어떠한 책임과 보상을 하지 않습니다.</p>
                    <p class="agree">5. 환불/취소를 원하시면 문의 게시판에 환불/취소 의사와 계좌번호를 남겨주시면 됩니다.</p>
                    
                    <div class="agree-wrapper">
                      <input id="mypage-agree-check" ref="mypage_chk_agree" type="checkbox" class="normal-checkbox"  /> 
                      <label for="mypage-agree-check" class="link">위의 내용을 확인했고, 동의합니다.</label>
                    </div>
                  </div>
                </div>
                <Divider />
                <div class="div_center">
                  <Button raised @click="click({type:'reg'})"  label="주문하기" />
                </div>
              </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
    <div v-else >
      <login @login="login" /> 
      <br>
      <div class="card main no-login-title">
        <ul>
          <li><a href="https://geek9.kr/shop" target="_blank">해외중고샵</a>에서 상품 검색이 가능합니다.</li>
          <li>해외중고상품이기 때문에 관심/카트에 등록된 상품이라도 품절이 될 수 있습니다.</li>
        </ul>
      </div>
    </div> 
    <div class="ad-container">
      <Adsense
        data-ad-client="ca-pub-6699354447815429" 
        data-ad-slot="8230887469"
        data-ad-format="horizontal"
        data-full-width-responsive="true">
      </Adsense>
    </div>
    <Modal v-model='isShow' :close='closeModal' style="z-index: 99999999999;">
      <div v-if="imgList.length !== 0" class="modal">
        <div class="div_title" style="text-align: left;margin-top:-20px;">
          <span style="color: #ff7f50;">상품사진</span>
        </div>
        <div class="div_center">
          <Carousel :value="imgList" :responsiveOptions="responsiveOptions" :numVisible="1" :numScroll="1"  style="width:100%">
            <template #item="slotProps">
              <img :src="slotProps.data" alt="GEEK9" />
            </template>
          </Carousel>
        </div>
        <span>
          <Button @click="closeModal()" raised label="닫기"/>
        </span>
      </div>
      <div v-else-if="desc !== ''" class="modal">
        <div class="div_title">
          <span style="color: #ff7f50;">해외판매자 상품설명</span>
          <label style="font-weight: normal;float:right;font-style:italic;" ><a target="_blank" :href="url">{{country}}어 번역하기</a></label>
        </div>
        <br>
        <p>{{ desc }}</p>
        <br> 
        <span>
          <Button raised size="small" @click="closeModal()" label="닫기" />
        </span>
      </div>
    </Modal>
  </div>
</template>

<script>

// import { useMeta } from 'vue-meta'
import router from './router'
import { ref, inject } from 'vue'
import context from '@/components/common/Context.vue'
import Carousel from 'primevue/carousel'
import policies from '@/components/common/policies.vue'
import ssr from '@/components/common/ssr'
import login from '@/components/common/Login.vue'

export default {
  emits: ["setCookies", "setMenu", "login"],
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 마이페이지 > 관심/카트'
    const keywords = '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,관심,카트,구매,거래,해외거래'
    const description = '관심상품과 카트에 담긴 해외 상품을 조회할 수 있습니다.'
    const geek9Url = ssr.GEEK9_URL + 'mypage'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    // useMeta( {
    //   title: 'GEEK9 > 마이페이지 > 관심/카트',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/mypage'}],
    //   meta: [
    //     { vmid: 'charset_mypage', charset: 'utf-8' },
    //     { vmid: 'http-equiv_mypage', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_mypage', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_mypage', name: 'keywords', content:'직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,관심,카트,구매,거래,해외거래,' },
    //     { vmid: 'viewport_mypage', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_mypage', name: 'description', content: '관심상품과 카트에 담긴 해외 상품을 조회할 수 있습니다.' },
    //     { vmid: 'theme-color_mypage', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_mypage', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_mypage', property: 'og:description', content: '관심상품과 카트에 담긴 해외 상품을 조회할 수 있습니다.'},
    //     { vmid: 'og:title_mypage', property: 'og:title', content: 'GEEK9 > 마이페이지 > 관심/카트'},
    //     { vmid: 'og:image_mypage', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_mypage', property: 'og:url', content: 'https://geek9.kr/mypage'}
    //   ]
    // })    
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const isShow = ref(false)
    let imgList = ref([])
    let desc = ref()
    let country = ref()
    let url = ref()
    function showModal (param) {
      const type = param.type
      if(type === 'imgList') {
        desc.value = ''
        country.value = ''
        url.value = ''
        imgList.value = param.imgList
      } else if(type === 'desc'){
        desc.value = param.desc 
        country.value = param.country
        url.value = param.url
        imgList.value = []
      }
      isShow.value = true
    }
    function closeModal () {
      isShow.value = false
    }
    const cate = ref([
      { label: '마이페이지', },
      { label: '관심/카트', route: '/mypage' }
    ])
    const responsiveOptions = ref([
      {
        breakpoint: '1400px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      }
    ])
    return {
      isShow, closeModal, showModal, imgList, desc, country, url, responsiveOptions, cate, addJsonLd
    }
  },
  components: {
    Carousel, context, policies, login
  },
  unmounted() {
    this.emitter.off('mypageReload', this.reload)
  },
  mounted() {
    this.addJsonLd()
    this.emitter.on('mypageReload', this.reload)
    this.api.setPage('mypage')
    console.log(this.api.getCookies('page'))
    const geektsLike = this.$route.query
    if(this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true) {
      if(geektsLike.url !== undefined) { 
        this.emitter.emit('dialog', {flag:true, title:'loading'})
        const item = {}
        item.id = this.api.getCookies('id')
        item.type = 'INSERT_ORDER_LIKE'
        item.name = geektsLike.name
        item.country = geektsLike.country
        item.description = geektsLike.description
        item.imgUrl = geektsLike.imgUrl
        item.originPrice = geektsLike.originPrice
        item.sellPrice = geektsLike.sellPrice
        item.url = geektsLike.url
        item.imgList = geektsLike.imgList
        item.foreignDeliveryPrice = 6

        this.api.axiosGql(item.type, item)
        .then( async (res) => {
          if(res.status === 200) {
            this.emitter.emit('confirm', {message: '관심상품으로 등록되었습니다.', header:'관심', icon:'pi pi-info-circle'})
          } else alert(res.data.error)
        }).catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요')
        }).finally( () => {
          this.emitter.emit('dialog', {flag:false, title:'loading'})
          this.search({type:'SELECT_ORDER_LIKE', page:1})
        })
      } else this.search({type:'SELECT_ORDER_LIKE', page:1})
    } else if(geektsLike.url !== undefined) {
      this.emitter.emit('confirm', {message: '로그인을 해주세요.', header:'관심', icon:'pi pi-info-circle'})
    }
    window.scrollTo(0, 0)
  },
  data() { 
    return { 
      tIdx: '0',
      selectedCountry:'',
      likeSelectedRows:[],
      likeExpandedRows:{},
      cartSelectedRows:[],
      // currentSlide: [],
      // currentModalSlide: 0,
      mypageKey: 0,
      page:1,
      likePage:1,
      contractList: [],
      // line: '',
      likeList:[],
      cartList:[],
      totalWonPrice:0,
      totalForeignPrice:0.0,
      totalForeignDeliveryPrice:0.0
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    onRowExpand(event) {
      this.likeExpandedRows = {[event.data.IDX]:true}
    },
    checkbox() {
      // mypage-wrapper
      let selectedWrapperId = ''
      if(this.tIdx === '0') selectedWrapperId = '#mypage-order'
      else selectedWrapperId = '#cart-wrapper'
      let checkedList = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]:checked')
      this.totalWonPrice = 0
      this.totalForeignPrice = 0
      this.totalForeignDeliveryPrice = 0.0

      for(let i=0; i<checkedList.length; i++) {
        const selectedIdx = Number(checkedList[i].dataset.checkbox)
        const order = this.cartList[selectedIdx]
        
        this.selectedCountry = order.COUNTRY
        const wonPrice = order.SELL_PRICE
        this.totalWonPrice += wonPrice
        const foreignDeliveryPrice = Number(order.FOREIGN_DELIVERY_PRICE)
        const originPrice = Number(order.ORIGIN_PRICE)
        const sellPrice = (originPrice - foreignDeliveryPrice).toFixed(2)
        this.totalForeignDeliveryPrice += foreignDeliveryPrice
        this.totalForeignPrice += Number(sellPrice)

      }
      checkedList = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]:checked')

      if(checkedList.length === 0) {
        this.selectedCountry = ''
      }
    },
    // slideTo(param) {
    //   const val = param.val
    //   const idx = param.idx
    //   this.currentSlide[idx] = val
    // },
    async click(param) {
      let type = param.type
      if(type === 'contract') {
        router.push({name:type})
      } else if(type === 'menu') {
        this.tIdx = param.tIdx
        let p
        if(this.tIdx === '0') {
          type = 'SELECT_ORDER_LIKE'
          p = 1
          this.clearCartInfo()
        } else {
          type = 'SELECT_ORDER_CART'
          p = -1
        }
        this.search({type:type, page:p})
      } else if(type === 'selectAll') {
        if(this.tIdx === '0') this.likeSelectedRows.length === 0 ? this.likeSelectedRows = this.likeList : this.likeSelectedRows = []
        else {
          let selectedWrapperId = '#cart-wrapper'
          let checkedList = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]:checked')
          let list = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]')
          if(checkedList.length === 0) {
            for(let i=0; i<list.length; i++) {
              list[i].checked = true
              const selectedIdx = Number(i)
              const order = this.cartList[selectedIdx]
              this.selectedCountry = order.COUNTRY
              const wonPrice = order.SELL_PRICE
              this.totalWonPrice += wonPrice
              const foreignDeliveryPrice = Number(order.FOREIGN_DELIVERY_PRICE)
              const originPrice = Number(order.ORIGIN_PRICE)
              const sellPrice = (originPrice - foreignDeliveryPrice).toFixed(2)
              this.totalForeignDeliveryPrice += foreignDeliveryPrice
              this.totalForeignPrice += Number(sellPrice)
            }
          } else {
            for(let i=0; i<list.length; i++) {
              list[i].checked = false
            }
            this.clearCartInfo()
          }
        }
      // } else if(type === 'left') {
      //   let imgList = param.imgList
      //   const idx = param.idx
      //   if(this.currentSlide[idx] === 0) this.currentSlide[idx] = imgList.length - 1
      //   else this.currentSlide[idx]--
      // } else if(type === 'right') {
      //   let imgList = param.imgList
      //   const idx = param.idx
      //   if(this.currentSlide[idx] === imgList.length - 1) this.currentSlide[idx] = 0
      //   else this.currentSlide[idx]++
      // } else if(type === 'leftModal') {
      //   let imgList = param.imgList
      //   if(this.currentModalSlide === 0) this.currentModalSlide = imgList.length - 1
      //   else this.currentModalSlide--
      // } else if(type === 'rightModal') {
      //   let imgList = param.imgList
      //   if(this.currentModalSlide === imgList.length - 1) this.currentModalSlide = 0
      //   else this.currentModalSlide++
      } else if(type === 'DELETE_ORDER_LIKE') {
        const delIdxList = param.idxList
        let checkedList = []
        if(delIdxList === undefined) checkedList =  this.likeSelectedRows.map(item => Number(item.IDX))
        else checkedList = delIdxList
        
        if(checkedList.length === 0) {
          alert('삭제할 상품을 선택해주세요')
        } else {
          if(confirm('선택한 상품 ' + checkedList.length +'건을 삭제할까요?') === false) return
          else {
            this.emitter.emit('dialog', {flag:true, title:'loading'})
            await this.api.axiosGql(type, {type:type, list:checkedList})
            .then( async (res) => {
              if(res.status === 200) {
                if((this.likeList.length === checkedList.length) && this.likePage > 1) this.likePage--
                alert(checkedList.length + '건을 삭제했습니다')
                this.search({type:'SELECT_ORDER_LIKE', page:this.likePage})
              } else alert(res.data.error)
            }).catch( (err) => {
              console.log(err)
              alert('다시 시도해주세요')
            }).finally( () => {
              this.emitter.emit('dialog', {flag:false, title:'loading'})
            })
          }
        }
      } else if(type === 'DELETE_ORDER_CART') {
        const delIdxList = param.idxList
        let checkedList = []
        if(delIdxList === undefined) {
          let selectedWrapperId = ''
          if(this.tIdx === '0') selectedWrapperId = '#mypage-order'
          else selectedWrapperId = '#cart-wrapper'
          let tmpList = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]:checked')

          tmpList.forEach((item) => {
            checkedList.push(Number(item.value))
          })
        } else checkedList = delIdxList
        
        if(checkedList.length === 0) {
          alert('삭제할 상품을 선택해주세요')
        } else {
          if(confirm('선택한 상품 ' + checkedList.length +'건을 삭제할까요?') === false) return
          else {
            this.emitter.emit('dialog', {flag:true, title:'loading'})
            await this.api.axiosGql(type, {type:type, list:checkedList})
            .then( async (res) => {
              if(res.status === 200) {
                this.cartList = []
                this.clearCartInfo()
                this.search({type:'SELECT_ORDER_CART', page:-1})
                alert(checkedList.length + '건을 삭제했습니다')
              } else alert(res.data.error)
            }).catch( (err) => {
              console.log(err)
              alert('다시 시도해주세요')
            }).finally( () => {
              this.emitter.emit('dialog', {flag:false, title:'loading'})
            })
          }
        }
      } else if(type === 'cart') {
        let checkedList = document.querySelectorAll('#cart-wrapper input[type="checkbox"]:checked')
        if(checkedList.length === 0) {
          alert('카트에 추가할 상품을 선택해주세요')
        }
      } else if(type === 'desc') {
        const desc = param.desc
        if(desc === undefined || desc === '') alert('판매자 설명이 없습니다.')
        else {
          const country = param.country
          param.url = this.api.getGoogleTranslateUrl({country:country, desc:desc})
          this.showModal(param)
        }
      } else if(type === 'imgList') {
        const imgList = param.imgList
        this.showModal({type:type, imgList:imgList.split(',')})
      // } else if(type === 'slide') {
      //   this.currentModalSlide = param.idx
      } else if(type === 'regOne') {
        const json = param.item
        let list = []
        list.push(json)
        router.push({ name: "order", params:  {'list':JSON.stringify(list)}})
      } else if(type === 'reg') {
        if(this.$refs.mypage_chk_agree.checked) {
          
          let selectedWrapperId = '#cart-wrapper'
          let checkedList = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]:checked')
          if(checkedList.length === 0) alert('주문할 상품을 선택해주세요')
          else {
            if(confirm('주문하시겠습니까?') === false) return
            let initCountry = ''
            let list = []
            let isValid = true
            for(let i=0; i<checkedList.length; i++) {
              const selectedIdx = Number(checkedList[i].dataset.checkbox)
              const country = this.cartList[selectedIdx].COUNTRY
              if(initCountry === '') {
                initCountry = country
                const order = this.cartList[selectedIdx]
                const json  = { URL: order.URL, NAME: order.NAME, ORIGIN_PRICE:order.ORIGIN_PRICE, COUNTRY:order.COUNTRY, IMG_URL:order.IMG_URL, AMOUNT:1, ORDER_NUMBER:(i+1), WON_PRICE:order.SELL_PRICE }
                list.push(json)
                if(checkedList.length === 1) break
              } else {
                if(initCountry !== country) {
                  alert('합배송은 동일한 국가의 상품끼리만 가능합니다.')
                  isValid = false
                  break
                } else {
                  const order = this.cartList[selectedIdx]
                  const json  = { 
                    URL: order.URL, 
                    NAME: order.NAME, 
                    ORIGIN_PRICE:order.ORIGIN_PRICE, 
                    country:order.COUNTRY, 
                    IMG_URL:order.IMG_URL, 
                    AMOUNT:1, 
                    ORDER_NUMBER:(i+1), 
                    WON_PRICE:order.SELL_PRICE 
                  }
                  list.push(json)
                }
              }
            }
            if(isValid) router.push({ name: "order", params:  {'list':JSON.stringify(list)}})
          }
        } else {
          const el = document.getElementById('mypage_agree_section')
          el.setAttribute('class', 'blink')
          alert("주의사항에 동의해주셔야 등록이 가능합니다.")
        }
      } else if(type === 'INSERT_ORDER_CART') {
        let item = param.item
        
        if(item === undefined) {
          if(this.likeSelectedRows.length === 0) alert('상품을 선택해주세요')
          else {
            for(let i=0; i<this.likeSelectedRows.length; i++) {
              await this.insert({type:type, item:this.likeSelectedRows[i]})
            }
          }
        } else this.insert({type:type, item:item})
      }
    },
    async insert(param) {
      const type = param.type
      const item = param.item
      let p = {
        id:this.api.getCookies('id'), 
        type:type,
        country:item.COUNTRY, 
        url:item.URL, 
        name:item.NAME, 
        imgUrl:item.IMG_URL, 
        brand:item.BRAND, 
        foreignDeliveryPrice:item.FOREIGN_DELIVERY_PRICE, 
        originPrice:item.ORIGIN_PRICE, 
        sellPrice:item.SELL_PRICE, 
        imgList:item.IMG_LIST, 
        size:item.SIZE, 
        description:item.DESC
      }
      await this.api.axiosGql(type, p)
      .then( async (res) => {
        if(res.status === 200) {
          // alert('카트에 담았습니다.')
          this.emitter.emit('confirm', {message: '카트에 담았습니다.', header:'카트', icon:'pi pi-info-circle'})
        } else {
          const errMsg = res.data.error
          if(errMsg.indexOf('이미') !== -1) this.emitter.emit('confirm', {message: errMsg, header:'카트', icon:'pi pi-exclamation-triangle'})
        }
      }).catch( (err) => {
        console.log(err)
        alert('다시 시도해주세요')
      }).finally( () => {
        this.emitter.emit('dialog', {flag:false, title:'loading'})
      })
    },
    async search(param) {
      this.emitter.emit('dialog', {flag:true, title:'loading'})
      const type = param.type
      const page = param.page
      param.id = this.api.getCookies('id')
      param.page = Number(page)
      this.api.axiosGql(type, param)
      .then( async (res) => {
        const result = res.data
        if(res.status === 200) {
          // if(type === 'SELECT_ORDER_LIKE') {
          //   this.currentSlide = []
          //   for(let i=0; i<result.length; i++) {
          //     this.currentSlide.push(0)
          //   }
          // }
          if(result.length !== 0) {
            if(this.tIdx === '0') this.likeList = result
            else this.cartList = result
            this.mypageKey++
          }
        }
      }).catch( (err) => {
        console.log(err)
        alert('다시 시도해주세요')
      }).finally( () => {
        this.emitter.emit('dialog', {flag:false, title:'loading'})
      })
    },
    getNextSaturday() {
      for(var i=1; i<8; i++) {
        let date = new Date()
        let diffDate = new Date(date.setDate(date.getDate() + i, date.getDay()))
        if(diffDate.getDay() === 6) {
          const nextSaturday = new Date(diffDate.getTime() - (diffDate.getTimezoneOffset() * 60000)).toISOString()
          return new Date(nextSaturday).toISOString().slice(0,10) + ' 00:00:00' 
        }
      }
    },
    getCookies(key) {
      return this.api.getCookies(key)
    },
    reload(flag) {
      if(flag === 'dashboard') {
        this.likeList = []
        this.cartList = []
        this.mypageKey++
        this.$emit("setMenu", {page:'mypage'})
        // let menu = document.getElementById('mypageMenu')
        // if(!(menu === undefined || menu === null) ) menu.setAttribute("class", "active")
        this.tIdx = '0'
        this.search({type:'SELECT_ORDER_LIKE', page:1})
      }
    },
    likePaging(type) {
      if(type === 'prev') {
        if(this.likePage === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.likePage -= 1
      } else this.likePage += 1
      this.api.axiosGql('SELECT_ORDER_LIKE', {id:this.api.getCookies('id'), page:this.likePage, type:'SELECT_ORDER_LIKE'})
      .then( async (res) => {
        let json = res.data
        if(json.length === 0) {
          this.likePage -= 1
          alert('마지막 페이지입니다.')
        } else {
          this.likeList = json
          const el = document.getElementById('mypageMenu')
          el.scrollIntoView(el.scrollTop)
        }
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.likePage += 1
        else this.likePage -= 1
      })
    },
    clearCartInfo() {
      this.totalWonPrice = 0,
      this.totalForeignPrice = 0.0,
      this.totalForeignDeliveryPrice = 0.0
      this.selectedCountry = ''
    }
  }
}
</script>

<style scoped>
.mypage-table td:nth-child(even){
  text-align: center;
  border: 1px dotted #ffa07a; 
}
.mypage-table td:nth-child(odd){
  text-align: center;
  width:20%;
  border: 1px dotted #ffa07a; 
}
.mypage-table tr {
  height:30px;
}
.mypage-table td:nth-child(odd) {
  border: 1px dotted #ffa07a; 
}
.mypage-table tr:nth-child(odd) td{
  text-align: center;
  border: 1px dotted #ffa07a; 
}
table {
  border-collapse: collapse;
}
.mypage-table tr:first-child td {
  border-top: 0;
}
.mypage-table tr td:first-child {
  border-left: 0;
}
.mypage-table tr:last-child td {
  border-bottom: 0;
}
.mypage-table tr td:last-child {
  border-right: 0;
}
.navbar {
  width: 100%;
  background-color: white;
  overflow: auto;
  cursor: pointer;
  background-color: lightgoldenrodyellow;
  border-radius: 9px;
}
.navbar span {
  padding-top: 5px;
  padding-bottom: 5px;
  float: left;
  color: black;
  text-align: center;
  border-radius: 9px;
}
.navbar span.active {
  background-color: lightsalmon;
  color: white;
  font-weight: bold;
}
.mypage-collapsible:after {
  content: '\002B';
  font-weight: bold;
  float: left;
  color: red;
  /* margin-left: 5px;
  margin-right: 5px; */
  position:absolute;
}
.select:after {
  content: "\2212";
}
.mypage-collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 15px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.mypage-content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.shop-modal_button {
  background-color: lightsalmon;  
  color: white;
  padding: 6px;
  margin:10px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  cursor: pointer;
  min-width:50px;
}
.cart-regist {
  width: 100%;
  margin-top: 25px;
  background-color: lightsalmon;  
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  min-width: 100px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mypage-menu {
  min-width:100px;
  font-weight: bold;
}
.mypage-value {
  width:100%;
}
</style>